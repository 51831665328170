import { combineReducers } from '@reduxjs/toolkit'

import userReducer from './user/reducers'
import appReducer from './app/reducers'

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
})

export default rootReducer
