import { FC } from 'react'
import { Suspense, lazy } from 'react'
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom'
import PageLoader from '../components/PageLoader'
import { ROUTE_PATHS } from './constants'
import CheckAuth from '../hoc/checkAuth'
import LayoutWrapper from '../layout'

const Dashboard = lazy(() => import('../pages/Dashboard'))

const Auth = lazy(() => import('../pages/Auth'))
const PageNotFound = lazy(() => import('../pages/404'))
const CreateStore = lazy(() => import('../pages/CreateStore'))
const Logout = lazy(() => import('../pages/Logout'))
// product
const CreateProduct = lazy(() => import('../pages/Catalogue/Product/CreateProduct'))
const Products = lazy(() => import('../pages/Catalogue/Product'))
const ProductDetails = lazy(() => import('../pages/Catalogue/Product/ProductDetails'))
const EditProduct = lazy(() => import('../pages/Catalogue/Product/CreateProduct'))
// category
const Categories = lazy(() => import('../pages/Catalogue/Categories'))
const CreateCategory = lazy(() => import('../pages/Catalogue/Categories/CreateCategory'))
const EditCategory = lazy(() => import('../pages/Catalogue/Categories/CreateCategory'))
const CategoryDetails = lazy(() => import('../pages/Catalogue/Categories/CategoryDetails'))
// media
const Media = lazy(() => import('../pages/Media'))
const AddMedia = lazy(() => import('../pages/Media/AddMedia'))
// settings
const Settings = lazy(() => import('../pages/Settings'))
//Quick Menu
const QuickCreateProduct = lazy(() => import('../pages/Catalogue/Product/QuickCreateProduct'))

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <LayoutWrapper>
          <Routes>
            {/* Protected routes */}
            <Route
              path={ROUTE_PATHS.HOME}
              element={
                <CheckAuth>
                  <Navigate to={ROUTE_PATHS.DASHBOARD} replace={true} />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.DASHBOARD}
              element={
                <CheckAuth>
                  <Dashboard />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.CREATE_STORE}
              element={
                <CheckAuth>
                  <CreateStore />
                </CheckAuth>
              }
            />

            {/* Product Route */}
            <Route
              path={ROUTE_PATHS.PRODUCT_CREATE}
              element={
                <CheckAuth>
                  <CreateProduct />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.PRODUCT_EDIT}/:id`}
              element={
                <CheckAuth>
                  <EditProduct />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.PRODUCT_DETAILS}/:id`}
              element={
                <CheckAuth>
                  <ProductDetails />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.PRODUCTS}
              element={
                <CheckAuth>
                  <Products />
                </CheckAuth>
              }
            />
            {/* Category Route */}
            <Route
              path={ROUTE_PATHS.CATEGORY_CREATE}
              element={
                <CheckAuth>
                  <CreateCategory />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.CATEGORY_EDIT}/:id`}
              element={
                <CheckAuth>
                  <EditCategory />
                </CheckAuth>
              }
            />
            <Route
              path={`${ROUTE_PATHS.CATEGORY_DETAILS}/:id`}
              element={
                <CheckAuth>
                  <CategoryDetails />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.CATEGORIES}
              element={
                <CheckAuth>
                  <Categories />
                </CheckAuth>
              }
            />

            {/* Media Route */}
            <Route
              path={ROUTE_PATHS.ADD_MEDIA}
              element={
                <CheckAuth>
                  <AddMedia />
                </CheckAuth>
              }
            />
            <Route
              path={ROUTE_PATHS.MEDIA}
              element={
                <CheckAuth>
                  <Media />
                </CheckAuth>
              }
            />

            {/* Settings Route */}
            <Route
              path={ROUTE_PATHS.SETTINGS}
              element={
                <CheckAuth>
                  <Settings />
                </CheckAuth>
              }
            />

            {/* Quick Menu */}
            <Route
              path={ROUTE_PATHS.QUICK_CREATE_PRODUCT}
              element={
                <CheckAuth>
                  <QuickCreateProduct />
                </CheckAuth>
              }
            />

            {/* Public Routes */}

            <Route path={ROUTE_PATHS.AUTH} element={<Auth />} />
            <Route path={ROUTE_PATHS.LOGOUT} element={<Logout />} />

            {/* 404 */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </LayoutWrapper>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRouter
