import React from 'react'
import { Layout } from 'antd'

const { Content } = Layout

interface Props {
  children: React.ReactNode
}

const FullPageLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout style={{ height: '100%' }} className="full-page-layout">
      <Content style={{ height: '100%' }}>{children}</Content>
    </Layout>
  )
}

export default FullPageLayout
