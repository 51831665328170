export const ROUTE_PATHS = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  CREATE_STORE: '/create-store',

  //Catalogue
  PRODUCTS: '/catalogue/products',
  PRODUCT_CREATE: '/catalogue/product/create',
  PRODUCT_EDIT: '/catalogue/product/edit',
  PRODUCT_DETAILS: '/catalogue/product',

  //Category
  CATEGORIES: '/catalogue/categories',
  CATEGORY_CREATE: '/catalogue/category/create',
  CATEGORY_EDIT: '/catalogue/category/edit',
  CATEGORY_DETAILS: '/catalogue/category',

  //Media
  MEDIA: '/media',
  ADD_MEDIA: '/media/add',

  //Settings
  SETTINGS: '/settings',

  //Quick Menu
  QUICK_CREATE_PRODUCT: '/quick-create/product',
  QUICK_CREATE_ORDER: '/quick-create/order',
  QUICK_CREATE_CATEGORY: '/quick-create/category',

  LOGOUT: '/logout',
  AUTH: '/auth',
}
