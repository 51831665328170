import { FC } from 'react'

interface BrandLogoProps {
  collapsed?: boolean
}

const BrandLogo: FC<BrandLogoProps> = ({ collapsed = false }) => {
  return (
    <div className="brand-logo" style={{ display: 'flex', flexDirection: 'column' }}>
      {collapsed ? (
        <img
          style={{ width: '100%', height: '64px' }}
          src="https://res.cloudinary.com/dkvcuqr2o/image/upload/v1726815332/shopap/59940395_ymbeft.png"
          alt="Brand Icon"
        />
      ) : (
        <img
          style={{ width: '100%', height: '64px' }}
          src="https://res.cloudinary.com/dkvcuqr2o/image/upload/v1726828362/shopap/shopap-logo-new_zzmfaw.png"
          alt="Brand Logo"
        />
      )}
    </div>
  )
}

export default BrandLogo
