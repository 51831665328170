/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Layout, Menu, Divider, Switch, message } from 'antd'
import {
  LogoutOutlined,
  ShoppingOutlined,
  OrderedListOutlined,
  BarChartOutlined,
  SettingOutlined,
  AppstoreOutlined,
  TagOutlined,
  UserOutlined,
  PayCircleOutlined,
  ApiOutlined,
  QrcodeOutlined,
  CameraOutlined,
  ProductOutlined,
  SoundOutlined,
  HomeOutlined,
  TeamOutlined,
  MoneyCollectOutlined,
  MonitorOutlined,
  ShoppingCartOutlined,
  SendOutlined,
  ScanOutlined,
  ControlOutlined,
  ContactsOutlined,
  CarryOutOutlined,
  WhatsAppOutlined,
  GroupOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import BrandLogo from '../../components/BrandLogo'
import { getAppState } from '../../redux/app/actions'
import { useSelector } from 'react-redux'
import StoreQRCodeModal from '../../components/Modals/StoreQRCodeModal'
import QuickActionDrawer from './components/QuickActionDrawer'
import useIsMobile from '../../hooks/useIsMobile'
import api from '../../utils/api'
import { setAuthTokens } from '../../utils/auth'
import { AppState, LayoutType } from '../../redux/app/types'
import { setAppState, setApploading } from '../../redux/app/reducers'
import { setUser } from '../../redux/user/reducers'
import { useDispatch } from 'react-redux'

const { Sider, Content } = Layout
const { SubMenu } = Menu

const storeFrontUrl = process.env.REACT_APP_STOREFRONT_APP || 'https://shopap.in/'

interface Props {
  children: React.ReactNode
}

const SidebarLayout: React.FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [isDarkTheme, setIsDarkTheme] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showQuickActionDrawer, setshowQuickActionDrawer] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const selectedStore = useSelector(getAppState).selectedStore
  const isStoreSelected = selectedStore.id || ''
  const storeUrl = `${storeFrontUrl}/${selectedStore.store_domain}`
  const stores = useSelector(getAppState).stores
  const isMobile = useIsMobile()
  const dispatch = useDispatch()

  const handleLogout = () => {
    navigate('/logout')
  }

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme)
  }

  const handleMenuClick = (path: string) => {
    navigate(path)
  }

  const showQrModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const navigateToProfile = () => {
    navigate('/user-profile')
  }

  const showDrawer = () => {
    setshowQuickActionDrawer(true)
  }

  const hideDrawer = () => {
    setshowQuickActionDrawer(false)
  }

  const handleSwitchAccount = (selectedStore: any) => {
    setApploading(true)
    api
      .post('/auth/switch-store', { store: selectedStore.store_id })
      .then((response: any) => {
        setAuthTokens(response.access_token, response.refresh_token)

        const appState: AppState = {
          isAuth: true,
          isAppLoading: false,
          token: {
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
          },
          selectedStore: response.selected_store || {},
          stores: response?.stores || [],
          layout: {
            type: LayoutType.SIDEBAR,
            isMobile: false,
          },
        }
        if (isMobile) {
          appState.layout.isMobile = true
          appState.layout.type = LayoutType.SIDEBAR
        }
        dispatch(setAppState(appState))
        dispatch(setUser(response.user))
        message.success(`Welcome ${response.user.display_name ? response.user.display_name : response.user.phone}  !`)
        if (appState.stores.length === 0) {
          message.warning('No stores found for this user')
          navigate('/create-store')
          return
        }
        if (appState.selectedStore && appState.selectedStore.id) {
          message.success(`Logged in as ${response.user.display_name} to ${response.selected_store.store_domain}`)
          navigate('/dashboard')
          return
        }
        message.warning('Please select a store to continue')
      })
      .catch((error: any) => {
        message.error('Failed to switch account: ' + error.message)
      })
      .finally(() => {
        setApploading(false)
      })
  }

  const menuItems = [
    { key: 'dashboard', icon: <HomeOutlined />, label: 'Dashboard', path: '/dashboard' },
    {
      key: 'orders',
      icon: <CarryOutOutlined />,
      label: 'Orders',
      children: [
        { key: 'orders', icon: <OrderedListOutlined />, label: 'Orders', path: '/order/orders' },
        {
          key: 'abandoned-carts',
          icon: <ShoppingCartOutlined />,
          label: 'Abandoned',
          path: '/order/abandoned-carts',
        },
      ],
    },
    {
      key: 'catalogue',
      icon: <ShoppingOutlined />,
      label: 'Catalogue',
      children: [
        { key: 'products', icon: <AppstoreOutlined />, label: 'Products', path: '/catalogue/products' },
        { key: 'categories', icon: <TagOutlined />, label: 'Categories', path: '/catalogue/categories' },
        { key: 'Inventory', icon: <ScanOutlined />, label: 'Inventory', path: '/catalogue/inventory' },
      ],
    },
    {
      key: 'payments',
      icon: <PayCircleOutlined />,
      label: 'Payments',
      children: [
        { key: 'transactions', icon: <MonitorOutlined />, label: 'Transactions', path: '/payments/transactions' },
        { key: 'ledger', icon: <MoneyCollectOutlined />, label: 'Khata', path: '/payments/ledger' },
      ],
    },
    {
      key: 'campaigns',
      icon: <SoundOutlined />,
      label: 'Campaigns',
      children: [
        { key: 'WA Campaigns', icon: <WhatsAppOutlined />, label: 'WA Ads', path: '/campaigns/wa-campaigns' },
        { key: 'SMS Campaigns', icon: <SendOutlined />, label: 'SMS Ads', path: '/campaigns/sms-campaigns' },
        { key: 'Coupons', icon: <BarChartOutlined />, label: 'Coupons', path: '/campaigns/coupons' },
        { key: 'Promotions', icon: <ProductOutlined />, label: 'Promotions', path: '/campaigns/promotions' },
      ],
    },
    { key: 'Media', icon: <CameraOutlined />, label: 'Media', path: '/media' },
    { key: 'reports', icon: <BarChartOutlined />, label: 'Reports', path: '/reports' },
    { key: 'customers', icon: <ContactsOutlined />, label: 'Customers', path: '/customers' },

    { key: 'integrations', icon: <ApiOutlined />, label: 'Integrations', path: '/integrations' },
    { key: 'vendors', icon: <GroupOutlined />, label: 'Vendors', path: '/vendors' },
    { key: 'Members', icon: <TeamOutlined />, label: 'Team', path: '/staff' },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
      path: '/settings',
    },
  ]

  return (
    <Layout hasSider style={{ minHeight: '100vh' }} className="sidebar-layout">
      <Sider
        theme={isDarkTheme ? 'dark' : 'light'}
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          insetInlineStart: 0,
          top: 0,
          bottom: 0,
          scrollbarWidth: 'thin',
          scrollbarColor: 'unset',
        }}
      >
        <Link to="/dashboard">
          <BrandLogo collapsed={collapsed} />
        </Link>
        <Divider style={{ margin: '0' }} type="horizontal" />

        {isStoreSelected && (
          <Menu theme={isDarkTheme ? 'dark' : 'light'} mode="inline" style={{ height: '40px)', overflow: 'auto' }}>
            <SubMenu key="store" icon={<ShopOutlined />} title={collapsed ? '' : 'Stores'}>
              {stores.map(store => (
                <Menu.Item key={store.id} onClick={() => handleSwitchAccount(store)}>
                  {store.store_domain}
                </Menu.Item>
              ))}
            </SubMenu>
          </Menu>
        )}

        <Divider style={{ margin: '0' }} type="horizontal" />
        {isStoreSelected && (
          <div
            style={{
              display: 'flex',
              justifyContent: collapsed ? 'center' : 'space-around',
              padding: '10px 8px',
              flexDirection: collapsed ? 'column' : 'row',
              alignItems: 'center',
            }}
          >
            <QrcodeOutlined
              color={isDarkTheme ? '#fff' : '#000'}
              style={{ fontSize: '18px', margin: '5px', cursor: 'pointer' }}
              onClick={showQrModal}
            />
            <UserOutlined style={{ fontSize: '18px', margin: '5px', cursor: 'pointer' }} onClick={navigateToProfile} />
            <ControlOutlined style={{ fontSize: '18px', margin: '5px', cursor: 'pointer' }} onClick={showDrawer} />
          </div>
        )}

        <Divider style={{ margin: '0' }} type="horizontal" />

        <Menu
          theme={isDarkTheme ? 'dark' : 'light'}
          mode="inline"
          style={{ height: `calc(100% - ${isMobile ? '320px' : '260px'})`, overflow: 'auto' }}
          selectedKeys={[location.pathname]}
        >
          {isStoreSelected &&
            menuItems.map(item =>
              item.children ? (
                <SubMenu key={item.key} icon={item.icon} title={collapsed ? '' : item.label}>
                  {item.children.map(subItem => (
                    <Menu.Item key={subItem.path} icon={subItem.icon} onClick={() => handleMenuClick(subItem.path)}>
                      {subItem.label}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={item.path} icon={item.icon} onClick={() => handleMenuClick(item.path)}>
                  {item.label}
                </Menu.Item>
              ),
            )}
        </Menu>
        <Menu
          theme={isDarkTheme ? 'dark' : 'light'}
          mode="inline"
          style={{ position: 'absolute', bottom: 48, width: '100%' }}
        >
          <Divider style={{ margin: '0' }} type="horizontal" />
          <Menu.Item key="theme" icon={<Switch checked={isDarkTheme} onChange={toggleTheme} />}>
            {collapsed ? '' : isDarkTheme ? 'Dark' : 'Light'}
          </Menu.Item>
          <Divider style={{ margin: '0' }} type="horizontal" />
          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
            {collapsed ? '' : 'Logout'}
          </Menu.Item>
          <Divider style={{ margin: '0' }} type="horizontal" />
        </Menu>
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: 'margin-left 0.2s',
          marginInlineStart: collapsed ? 80 : 200,
        }}
      >
        <Content style={{ margin: '5px 8px 0', overflow: 'initial' }}>{children}</Content>
      </Layout>

      {/* Store QR Code */}
      <StoreQRCodeModal isModalVisible={isModalVisible} setIsModalVisible={handleCancel} url={storeUrl} />

      {/* Quick Store Action Modal */}
      <QuickActionDrawer isOpen={showQuickActionDrawer} setOpen={hideDrawer} />
    </Layout>
  )
}

export default SidebarLayout
